@import-normalize;
@import './Assets/Fonts/fontawesome-free/css/all.min.css';
@import 'utils/Stylesheet/colors.scss';
@import 'utils/Stylesheet/mixins.scss';

:root {
    scrollbar-color: none rgba(60, 77, 96, 0.14) !important;
    scrollbar-width: thin !important;
}

* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $white_5;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.js-focus-visible {
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}
::-webkit-scrollbar-track {
    background: none;
    padding-right: 0px;
    width: 4px;
}
::-webkit-scrollbar-thumb {
    background: rgba(60, 77, 96, 0.14);
    border: 1px solid rgba(60, 77, 96, 0.14);
    border-radius: 30px;
    width: 3px;
    background-clip: padding-box;
}
.toggle-active {
    color: #0029ff !important;
}
.toggle-inactive {
    color: rgba(78, 81, 91, 0.8) !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
}

svg {
    color: inherit;
    path {
        color: inherit;
    }
}

.width-60 {
    width: 60%;
}
.width-75 {
    width: 75%;
}
.width-80 {
    width: 80%;
}
.width-80px {
    width: 80px;
}
.width-100 {
    width: 100%;
}
.width-100px {
    width: 100px;
}
.width-120 {
    width: 120px !important;
}
.width-132 {
    width: 132px;
}
.width-137 {
    width: 137px;
}
.width-140 {
    width: 140px;
}
.width-144 {
    width: 144px !important;
}
.width-160 {
    width: 160px;
}
.width-166 {
    width: 166px;
}
.width-167 {
    width: 167px;
}
.width-171 {
    width: 171px;
}
.width-180 {
    width: 180px;
}
.width-188 {
    width: 188px;
}
.width-200 {
    width: 200px !important;
}
.width-201 {
    width: 201px;
}
.width-203 {
    width: 203px;
}
.width-216 {
    width: 216px;
}
.width-228 {
    width: 228px;
}
.width-236 {
    width: 236px;
}
.width-240 {
    width: 240px;
}
.width-279 {
    width: 279px;
}
.width-292 {
    width: 292px;
}
.width-300 {
    width: 300px;
}
.width-313 {
    width: 313px;
}
.width-402 {
    width: 402px;
}
.width-350 {
    width: 350px !important;
}
.width-378 {
    width: 378px !important;
}
.width-455 {
    width: 445px !important;
}
.width-491 {
    width: 491px;
}
.width-498 {
    width: 498px;
}
.width-500 {
    width: 500px;
}
.width-540 {
    width: 540px;
}

.width-700 {
    min-width: 700px;
}
.min-width-70 {
    min-width: 70px;
}

.max-width-362 {
    max-width: 362px;
}

.description-box {
    width: 480px;
    height: 60px;
}

.filter-by-label {
    @include font-details('Open Sans', 0.875rem, 400, 1.25rem);
    margin-right: 11px;
    margin-bottom: 0px;
}

.display-none {
    display: none;
}
.visibility-hidden {
    visibility: hidden;
}

.button-colour {
    height: 51px;
    width: 200px;
    background: #0029ff;
    border-radius: 100px;
    color: #ffffff;
    font-size: 1.25em;
    &:hover {
        color: #ffffff !important;
    }
}
.nav-link.active {
    font-weight: 900;
}

.font-size-14 {
    font-size: 0.875rem !important;
}

.font-size-16 {
    font-size: 1rem !important;
}

.font-size-10 {
    font-size: 10px !important;
}

.color-normal {
    color: #4e515b !important;
}

.font-size-12 {
    font-size: 0.75rem !important;
}

.font-size-18 {
    font-size: 1.125em !important;
}

.font-size-20 {
    font-size: 1.25em !important;
}

.font-size-32 {
    font-size: 2em;
}

.font-size-26 {
    font-size: 1.625em !important;
}
.font-size-inherit {
    font-size: inherit;
}

.font-weight-600 {
    font-weight: 600;
}

a {
    color: #0029ff;
}

.color-violet {
    color: #7716d8 !important;
}

.color-blue-1 {
    color: $blue_1 !important;
}

.a-default {
    cursor: pointer;
    color: $blue_1;
    font-weight: 600;
}

.opacity-80 {
    opacity: 0.8;
}

.colour-red {
    color: #f03d3d;
}

input:disabled {
    background-color: #fff;
}

.make-relative {
    display: inline-block !important;
    position: relative !important;
}

.colour-grey {
    color: #90949f;
}

.rewards-container {
    width: 602px;
    background: #ffffff;
    border: 1px solid #d2d9eb;
    box-sizing: border-box;
    border-radius: 4px;
    display: inline-block;
    position: relative;
    h5 {
        font-family: 'Open Sans', sans-serif;
    }
}

.font-open-sans {
    font-family: 'Open Sans', sans-serif;
}

.dots-blue {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #3e71f6;
    display: inline-block;
    position: relative;
}
.poppins {
    font-family: 'Poppins', sans-serif;
}

.css-6q0nyr-Svg,
.css-tlfecz-indicatorContainer {
    color: #0029ff !important;
}

.css-yk16xz-control {
    border-color: #c2cfff !important;
}
.MuiSwitch-thumb {
    height: 10px !important;
    width: 10px !important;
    margin-top: 1px;
    margin-left: 1px;
}
.css-1wa3eu0-placeholder {
    font-size: 0.75em;
    color: #c2cfff !important;
}
.hollow-dots {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 1px solid #3e71f6;
    box-sizing: border-box;
    display: inline-block;
    position: relative;
}
.capitalize {
    text-transform: capitalize;
}

.css-26l3qy-menu {
    margin-top: 0px !important;
}

.height-191 {
    height: 191px !important;
}
.height-374 {
    max-height: 374px;
    overflow: hidden;
}
.height-420 {
    height: 420px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.height-195 {
    height: 195px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.height-487 {
    height: 487px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.height-261 {
    height: 261px;
    overflow: auto;
}

.height-350 {
    height: 350px;
}
.height-360 {
    height: 360px;
}
.height-300 {
    height: 300px;
}
.height-200 {
    height: 200px;
}
.height-115 {
    height: 115px;
}
.height-150 {
    height: 150px;
}
.height-100-vh {
    height: 100vh;
}

.height-fit-content {
    height: fit-content;
}
.screen-width {
    width: 100%;
    min-width: 980px;
}
.y-scroll {
    height: 100%;
    overflow-y: scroll;
}
.reward-text-input {
    height: 45px;
    width: 234px;
    border: 1px solid #c2cfff;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 0.75em;
    padding: 5px 5px 5px 15px;
    color: #4e515b;
}

.loader-style {
    position: absolute;
    display: block;
    border-color: red;
    left: 50%;
    top: 50%;
    filter: blur(8px);
    -webkit-filter: blur(8px);
}

.ms-img-onboarding-big {
    height: 36px !important;
    width: 36px !important;
    border-radius: 4px;
    background-color: #e1deed;
    text-align: center;
}

.ms-img-onboarding-small {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: #e1deed;
    font-size: 0.75em;
    text-align: center;
}
.align-center {
    text-align: center !important;
}
.align-right {
    text-align: right !important;
}
.padding-16 {
    padding: 16px;
}
.padding-20 {
    padding: 20px !important;
}
.padding-top-12 {
    padding-top: 12px;
}
.padding-top-20 {
    padding-top: 20px;
}
.padding-top-28 {
    padding-top: 28px;
}
.padding-top-36 {
    padding-top: 36px;
}
.padding-bottom-10 {
    padding-bottom: 10px;
}
.margin-auto {
    margin: auto !important;
}
.margin-botton-neg-65 {
    margin-bottom: -65px !important;
}
.margin-bottom-8 {
    margin-bottom: 8px !important;
}
.margin-bottom-10 {
    margin-bottom: 10px;
}
.margin-bottom-20 {
    margin-bottom: 20px;
}
.margin-bottom-24 {
    margin-bottom: 24px;
}
.margin-bottom-16 {
    margin-bottom: 16px;
}
.margin-bottom-40 {
    margin-bottom: 40px;
}
.margin-bottom-60 {
    margin-bottom: 60px;
}
.margin-bottom-100 {
    margin-bottom: 100px;
}
.margin-top-8 {
    margin-top: 8px;
}
.margin-top-10 {
    margin-top: 10px;
}
.margin-top-14 {
    margin-top: 14px;
}
.margin-top-20 {
    margin-top: 20px;
}
.margin-top-30 {
    margin-top: 30px;
}
.margin-top-35 {
    margin-top: 35px;
}
.margin-top-40 {
    margin-top: 40px;
}
.margin-top-60 {
    margin-top: 60px;
}
.margin-right-8 {
    margin-right: 0.5rem;
}
.margin-right-10 {
    margin-right: 10px;
}
.margin-right-16 {
    margin-right: 16px;
}
.margin-right-25 {
    margin-right: 25px;
}
.margin-right-50 {
    margin-right: 50px;
}
.margin-left-5 {
    margin-left: 5px;
}
.margin-left-15 {
    margin-left: 15px;
}
.margin-left-25 {
    margin-left: 25px;
}
.margin-left-65 {
    margin-left: 65px;
}
.bold {
    font-weight: bold;
}
.fw-600 {
    font-weight: 600;
}
.pointer-cursor {
    cursor: pointer;
}
.pointer-disable {
    cursor: not-allowed;
}
.small-button {
    max-width: 150px !important;
}

.red-border {
    border: 1px solid #ff2200 !important;
}

.font-color-error {
    color: #ff2200;
}

.font-color-secondaryCTA {
    color: #7c98ff;
}

.font-color-primaryCTA {
    color: #0029ff;
}

.placeholder-item {
    path {
        display: none;
    }
}

.colour-black {
    color: #4e515b;
}

input {
    height: 40px;
    border-radius: 8px;
}
.normal-text {
    font-weight: normal !important;
    font-style: normal !important;
}
.absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.avatar-16 {
    width: 16px;
    height: 16px;
    border-radius: 4px;
}

.avatar-20 {
    width: 20px;
    height: 20px;
    border-radius: 4px;
}

.avatar-24 {
    width: 24px;
    height: 24px;
    border-radius: 4px;
}
.avatar-26 {
    width: 26px;
    height: 26px;
    border-radius: 4px;
}
.avatar-32 {
    width: 32px;
    height: 32px;
    border-radius: 4px;
}

.success-label {
    background: #1eb89c;
    height: 23px;
    width: auto;
    border-radius: 90px;
    padding: 2px 8px;
    color: #f7f7f7;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 0.75rem;
}

.danger-label {
    @extend .success-label;
    background: #cc4f49;
}

.warning-label {
    @extend .success-label;
    background: #efad0a;
}

.ew-btn {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.43em;
    padding: 12px 40px;
    box-sizing: border-box;
    height: 44px;
    width: auto;
    border-radius: 8px;
    cursor: pointer;
    border: none;
    transition: background-color 350ms;
    &:focus {
        outline: none;
    }
    &:disabled {
        cursor: not-allowed;
    }
}
.ew-btn-slim {
    padding: 8px 16px;
}
.pb-btn {
    color: #f7f7fc;
    background-color: #5d5cf5;
    &:hover {
        background-color: #4749be;
        color: #f7f7fc;
    }
    &:disabled {
        background-color: #5d5cf580;
    }
}
.pr-btn {
    color: #ffffff;
    background-color: #e84f48;
}

.pg-btn {
    color: #ffffff;
    background-color: #03b575;
}

.ob-btn {
    color: #5d5cf5;
    border: 1px solid #5d5cf5;
    background-color: #fcfcfc;
    &:hover {
        color: #4749be;
        border: 1px solid #4749be;
    }
    &:disabled {
        color: #5e61ec;
        border: 1px solid #5e61ec;
    }
}

.transparent-btn {
    background-color: transparent;
    border: none;
    @include flex(row, center, flex-start);
}

.link-btn {
    border: none;
    font-weight: 600;
    line-height: 1.25rem;
    font-size: 0.875rem;
    color: $blue_1;
    text-align: center;
    background: none;
}

.or-btn {
    color: #ff2200;
    border: 1px solid #ff2200;
    background-color: #ffffff;
}

.ow-btn {
    @include font-details('Open Sans', 0.875rem, 400, 1.25rem, $black_1);
    border: 1px solid $white_3;
    &:hover {
        color: $blue_21;
        border: 1px solid $blue_1;
    }
}

.tb-btn {
    padding: 0;
    color: #5d5cf5;
    background-color: transparent;
}
.tr-btn {
    padding: 0;
    color: #ff2200;
    background-color: #ffffff;
}
.lb-btn {
    background: rgba(93, 92, 245, 0.1);
    color: #5d5cf5;
    &:hover {
        border: 1px solid #5d5cf5;
    }
}

.header-2 {
    font-size: 1.5rem;
    line-height: 1.5em;
    color: $primary_black;
}

.header-3 {
    font-weight: 700;
    font-style: normal;
    font-size: 1.125rem;
    line-height: 18px;
    color: $primary_black;
    margin: 0;
}

.header-4 {
    font-size: 1rem;
    font-weight: 600;
    line-height: 24px;
    color: $primary_black;
}

.body-3 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: $grey_4;
}

.pointer-events-none {
    pointer-events: none;
}

.navigatortabs {
    .nav-tabs {
        width: 100%;
        .nav-link {
            @extend h1;
            font-size: 0.875rem;
            font-weight: normal;
            line-height: 1.25rem;
            width: auto;
            border: 0 !important;
            border-bottom: 0.5px solid #e0e8ff !important;
            color: $black_2 !important;
            background-color: inherit;
            padding: 8px 12px;
            &:focus {
                outline: none;
            }
        }
        .active {
            background-color: inherit !important;
            color: $blue_1 !important;
            font-weight: 600;
            border-bottom: 2px solid $blue_1 !important;
        }
    }
}
.error-text {
    color: #f9543b;
}

.member {
    display: flex;
    cursor: pointer;

    img {
        @extend .avatar-16;
        margin-right: 6px;
        align-self: center;
    }
    p {
        font-weight: 600;
        color: $blue_1;
        margin: 0px;
        align-self: center;
    }
}

input[type='text'] {
    border: 1px solid #e5e7ea;
    box-sizing: border-box;
    height: 40px;
    border-radius: 8px;
    background-color: #ffffff;
    font-size: 0.875rem;
    color: #4e515b;
    padding: 10px 16px;
    &:hover {
        border: 1px solid #0029ff;
        outline: none;
        box-shadow: none;
    }
    &:focus {
        border: 1px solid #0029ff;
        outline: none;
        box-shadow: none;
    }
    &::placeholder {
        color: $black_1;
        font-size: 0.875rem;
    }
    &:disabled {
        background-color: #f1f3f6;
        cursor: not-allowed;
    }
}

.border-red {
    border: 1px solid $red_2 !important;
}

input[type='number'] {
    @extend input[type='text'];
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
    }
}

textarea {
    @extend input[type='text'];
    padding: 8px 16px;
    height: auto;
    resize: none;
    width: 100%;
    border-radius: 8px;
}

.country-selection-filter {
    display: flex;

    img {
        width: 24px;
        height: 17px;
        margin-right: 7px;
    }

    p {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: $primary_black;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.response-empty {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -45%);
    height: auto;
    div {
        border-radius: 100%;
    }
    .response-empty-image {
        background-image: URL('Assets/images/empty-flag.svg');
        height: 192px;
        width: 192px;
        background-size: cover;
    }
    .no-response-text {
        color: #2d56bd;
        font-style: italic;
        font-size: 1rem;
        margin-top: 1.5rem;
        text-align: center;
    }
}

.badge {
    border-radius: 4px;
    width: 45px;
    height: 22px;
    font-weight: 600;
    font-size: 12px;
    line-height: 21px;
    padding: 0px;
    background-color: $blue_1;
    color: white;
    margin: 8px;
}

.disable-view {
    opacity: 0.5;
    pointer-events: none;
}

.close-icon {
    color: $grey_1;
    font-size: 1.35rem !important;
    cursor: pointer !important;
}

.chip {
    color: #1a1a1a;
    background-color: #f1f3f6;
    padding: 12px 16px;
    display: inline-block;
    font-size: 0.75rem;
    line-height: 1.2em;
    border-radius: 100px;
    cursor: pointer;
}
.chip-selected {
    @extend .chip;
    background-color: rgba(93, 92, 245, 0.8);
    color: white;
}

.pre-wrap-text {
    white-space: pre-wrap;
}
