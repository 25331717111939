$blue_1: #5d5cf5;
$blue_2: #4c4bdb;
$blue_3: rgba(93, 92, 245, 0.8);
$blue_4: #aeaefa;
$blue_5: #dfdefd;
$blue_6: rgba(93, 92, 245, 0.1);
$blue_7: #cfd3ff;
$blue_8: #f4f6ff;
$blue_9: rgba(94, 97, 236, 0.1);
$blue_10: #0029ff;
$blue_11: #3c4d7d;
$blue_12: #5d5cf0;
$blue_13: rgba(239, 239, 254, 0.5);
$blue_14: #656886;
$blue_15: rgba(21, 34, 47, 0.5);
$blue_16: #7f7eff;
$blue_17: #5d5cf514;
$blue_18: rgba(93, 92, 245, 0.03);
$blue_19: #5d5cf533;
$blue_19: #e0e8ff;
$blue_20: #5d5cf51a;
$blue_21: #4749be;
$blue_22: #5b5af024;
$blue_23: #5d5cf529;
$blue_24: #d7d7ff99;
$blue_25: #4e1a73;
$blue_26: #f5f4ff;
$blue_27: #3a31a933;
$blue_28: #f5f4ff99;
$blue_29: #ededff80;
$blue_30: rgba(208, 220, 255, 0.25);
$blue_31: #5d5cf5;
$blue_32: #5d5cf566;

$red_1: #ff5353;
$red_2: #e84f48;
$red_3: #e84f4814;
$red_4: #ff5252;
$orange_1: #ff5b54;
$primary_black: #4e515b;
$primary_foreground: #1d1c1d;
$black_1: #15222f;
$black_2: #535961;
$black_3: #15222fb2;
$black_4: #5d5cf514;
$black_5: #153a51;
$black_6: #101828;
$black_7: #1a1a1a;
$black_8: #15171c;
$black_9: #0000001a;
$black_10: #252525;
$black: #000000;
$black_11: #333;
$black_12: #666;

$white_1: #fafafa;
$white_2: #fcfcfc;
$white_3: #e5e7ea;
$white_4: #f7f7fe;
$white_5: #f5f5f5;
$white_6: #dddeea;
$white_7: #efeffe;
$white_8: #f7f7ff;
$white_9: #fff7ef;
$white_10: #31313108;
$white: #ffffff;

$grey_1: #82878d;
$grey_2: #a9adb1;
$grey_3: #a5adbb;
$grey_4: #90949f;
$grey_5: #c4c4c4;
$grey_6: #545454;
$grey_7: #e5e5e5;
$grey_8: #3c4d7d;
$grey_9: #727171;
$grey_10: #4a4a4a;
$grey_11: rgba(78, 81, 91, 0.7);
$grey_12: #9fabcc;
$grey_13: #8a9096;
$grey_14: #15222f99;
$grey_15: #4e515b;
$grey_16: #f6f6f6;
$grey_17: #a3a3a3;
$grey_18: #15222f4d;
$grey_19: #535961cc;
$grey_20: #15222f1a;
$grey_21: #33333314;
$grey_22: #3944690d;
$grey_23: #999;
$grey_24: #00000014;
$grey_25: #5454544d;
$grey_26: #d3d3d3;
$grey_27: #f9f9f9;
$grey_28: #313131;
$grey_29: #a0aec0;
$grey_30: #e9e9e9;
$grey_31: #d2d2d2;
$grey_32: #e9e9ff;
$grey_33: #f0eded;
$grey_more_option_border: rgba(0, 0, 0, 0.2);
$grey_34: #a9adb1;
$grey_35: #5a687a;
$grey_36: #5a687a19;
$grey_37: #e0e8fe26;
$grey_38: #15222f80;

$grey_border: #e5e7ea;

$tag: #f1f3f6;

$line: #ccd5e1;

$dark_green: #1eb89c;
$green_1: #03b575;
$green_2: #03b57514;
$green_3: #f3fbf1;
$green_4: #a5e2c333;
$green_5: #a5e2c3;
$green_6: #f4fcf8;

$promoter: #48bb78;
$detractor: #ff6760;
$passive: #fac032;

$yellow_1: #ffeec3;
$yellow_2: #efad0a;
$yellow_3: #efad0a14;
$yellow_4: #ffdf8e;
$yellow_5: #ffeaa019;
$yellow_6: #ffeaa099;
$yellow_7: #f6efe2;
$yellow_8: #ffecca;
$yellow_8: #fff4d7;
$yellow_7: #fff4de;
$yellow_9: #ffefc6;
$yellow_10: #fffbf6;
$yellow_11: #ffc123;
$yellow_12: #f6efe2;
$yellow_13: #ffecca;
$light_blue: #dceaff;

$pink_1: #e4078a;
