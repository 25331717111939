@import 'utils/Stylesheet/colors.scss';
@import 'utils/Stylesheet/mixins.scss';

@mixin award-label-text {
    @include font-details('Open Sans', 0.75rem, 600, 16px, $black_2);
    text-transform: uppercase;
    margin-bottom: 8px;
    letter-spacing: 0.2px;
}

.container-launch-award {
    @include flex(column);
    .cla-tab-content {
        @include flex(row, center);
        margin: 28px 88px;
    }
}
