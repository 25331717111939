@import 'utils/Stylesheet/colors.scss';
@import 'utils/Stylesheet/mixins.scss';

.container-confirmation {
    @include flex(row);
    height: 100vh;

    .confirmation-content {
        @include flex(column);
        flex: 4;
        margin: 100px 0px 0px 150px;
        .confirmation-title {
            @include font-details('Montserrat', 1.75rem, 600, 2.5rem, $black_1);
            letter-spacing: 0.1px;
        }
        .confirmation-link-text {
            @include font-details('Open Sans', 1.25rem, 600, 1.75rem, $black_1);
            margin-top: 16px;
        }
        .confirmation-subtext {
            @include font-details('Open Sans', 0.875rem, 400, 1.25rem, $black_1);
            margin-top: 8px;
        }
    }
}
